import { StubbornAttribute, StubbornAttributeGroup } from '../../../../../../../../business/StubbornAttributeMapping';
import { basicAttributeGroup, makeComponentAttributeGroup, makeCustomAttributeGroup, makeEventAttributeGroup } from '../../commons/attributes';

const attributes = new Set<StubbornAttribute>();
const datasourceAttributes = new Set<StubbornAttribute>();

attributes.add({
  label: 'Nombre de la lista',
  name: 'listName',
  type: 'string',
  default: 'defaultName',
});
attributes.add({
  label: 'Alineación',
  name: 'alignItems',
  type: 'select',
  default: 'center',
  list: ['center', 'flex-start'],
});
attributes.add({
  label: 'Denso',
  name: 'dense',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Desactivar relleno',
  name: 'disablePadding',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Desactivar el margen',
  name: 'disableGutters',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Start icon',
  name: 'startIconName',
  type: 'string',
  default: '',
});
attributes.add({
  label: 'End icon',
  name: 'endIconName',
  type: 'string',
  default: '',
});

datasourceAttributes.add({
  label: 'Enabled',
  name: 'enabled',
  type: 'boolean',
  default: false,
});
datasourceAttributes.add({
  label: 'Service',
  name: 'service',
  type: 'serviceDatasource',
  default: {
    serviceName: '',
    params: {
      keys: [],
      values: [],
    },
    path: '',
  },
});
datasourceAttributes.add({
  label: 'Items',
  name: 'items',
  type: 'object',
  default: [{ listItemName: 'List item 1' }, { listItemName: 'List item 2' }, { listItemName: 'List item 3' }],
});

export const attributeGroups: StubbornAttributeGroup[] = [basicAttributeGroup, makeCustomAttributeGroup('Datasource', 'datasource', datasourceAttributes), makeComponentAttributeGroup(attributes), makeEventAttributeGroup()];
