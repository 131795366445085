import {StubbornAttribute, StubbornAttributeGroup} from "../../../../../../../../business/StubbornAttributeMapping";

import { basicAttributeGroup, makeComponentAttributeGroup, makeEventAttributeGroup } from "../../commons/attributes";

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Content',
  name: 'content',
  type: 'string',
  default: 'Nombre del Chip',
});
attributes.add({
  label: 'Variante',
  name: 'variant',
  type: 'select',
  default: 'filled',
  list: ['filled', 'outlined']
});
attributes.add({
  label: 'Clickable',
  name: 'clickable',
  type: 'boolean',
  default: true,
});
attributes.add({
  label: 'Disabled',
  name: 'disabled',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Tamaño',
  name: 'size',
  type: 'select',
  default: 'medium',
  list: ['medium', 'small']
});
attributes.add({
  label: 'Color',
  name: 'color',
  type: 'select',
  default: 'default',
  list: ['default', 'primary', 'secondary']
});
attributes.add({
  label: 'Avatar source',
  name: 'avatarSrc',
  type: 'string',
  default: ''
});
attributes.add({
  label: 'Descripción de Avatar',
  name: 'avatarAlt',
  type: 'string',
  default: 'Por defecto'
});
attributes.add({
  label: 'Icon',
  name: 'icon',
  type: 'string',
  default: ''
});
attributes.add({
  label: 'Delete icon',
  name: 'deleteIcon',
  type: 'string',
  default: ''
});

const eventAttributes: StubbornAttribute[] = [
  {
    label: "OnClick",
    name: "onClick",
    type: "code",
    default: "",
  },
  {
    label: "OnDeleteClick",
    name: "onDeleteClick",
    type: "code",
    default: "",
  },
];

export const attributeGroups: StubbornAttributeGroup[] = [
  basicAttributeGroup,
  makeComponentAttributeGroup(attributes),
  makeEventAttributeGroup(eventAttributes),
];
