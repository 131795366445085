import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Link, Paper, Toolbar, Avatar, Grid, Card, CardHeader, CardActionArea, Button, Icon, accordionActionsClasses, IconButton } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useEditorServiceContext } from '../../EditorServiceContext';
import { AppThemeType } from '../../../../../../theme';
import { PreviewModal } from './components/PreviewModal';
import { BaseAppConst } from '../../../../../const/BaseAppConst';
import { actionImages } from './toolbox';
import { useDrop } from 'react-dnd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { StubbornServiceAction } from '../../../../../business/StubbornAsset';
import { availablePanelsByType, useAssetContext } from '../../../../Private2/commons/context/AssetContext';

const { bg_color, dot_size, dot_space } = BaseAppConst.uiEditor;

const useStyles = makeStyles<AppThemeType>((theme) =>
  createStyles({
    container: {
      margin: 0,
      padding: 0,
      display: 'flex',
      height: `100%`,
      width: '100%',
    },
    dotsStyle: {
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 10,
      background: 'white',
    },
    cardSelected: {
      boxShadow: '0px 0px 0px 5px red',
    },
    clickable: {
      cursor: 'pointer',
      color: 'white',
      textDecoration: 'none',
    },
    nonClickable: {
      color: '#707EF9',
      textDecoration: 'none',
    },
    icons: { display: 'flex', padding: 10, height: 40, width: 40, justifyContent: 'center', alignContent: 'center', backgroundColor: '#707EF9' },
  }),
);

export const ServiceEditor = () => {
  const { service, setService, selectedAction, setSelectedAction } = useEditorServiceContext();
  const classes = useStyles();
  const [openPreview, setOpenPreview] = useState(false);
  const [draggableServices, setDraggableServices] = useState<StubbornServiceAction[]>(service.actions);
  const { setActivePanel, activePanel, selectedAsset } = useAssetContext();

  useEffect(() => {
    setDraggableServices(service.actions);
    console.log(service.actions);
  }, [service]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'service',
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const reorder = (list: StubbornServiceAction[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(draggableServices, result.source.index, result.destination.index);
    setSelectedAction(null);
    setDraggableServices(items);
    const newService = {
      ...service,
      actions: items,
    };
    setService(newService);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <PreviewModal open={openPreview} onClose={() => setOpenPreview(false)} />
      <Grid container style={{ background: '#2F343D', height: 66, margin: 0, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10, justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <IconButton
            style={{ color: 'white' }}
            onClick={() => {
              selectedAsset && setActivePanel(availablePanelsByType[selectedAsset?.type][0]);
            }}
          >
            <Icon>settings</Icon>
          </IconButton>
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />} style={{ marginLeft: 10 }}>
            <Link color="inherit" onClick={() => setSelectedAction(null)} className={classes.clickable}>
              {service.name}
            </Link>
            {selectedAction !== null && (
              <Link color="inherit" className={classes.nonClickable}>
                {service.actions[selectedAction].name}
              </Link>
            )}
          </Breadcrumbs>
        </div>
        <IconButton onClick={() => setOpenPreview(true)}>
          <Icon style={{ color: 'white', marginLeft: 20 }}>play_arrow</Icon>
        </IconButton>
      </Grid>
      <Grid container style={{ padding: 5 }} key={selectedAsset?.name || 'est'}>
        <Paper className={classes.container} ref={drop}>
          <Box className={classes.dotsStyle} mt={1}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Box className={classes.dotsStyle} mt={1}>
                    <Grid container direction="column" spacing={2} alignItems="center">
                      {draggableServices.map((action, index) => (
                        <Draggable key={action.name} draggableId={action.name} index={index}>
                          {(provided) => (
                            <Card onClick={() => setSelectedAction(index)} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} sx={{ width: 400, backgroundColor: '#EEEEEE', marginTop: 1 }} className={index === selectedAction ? classes.cardSelected : undefined}>
                              <CardHeader avatar={<Avatar src={actionImages[action.type.toLowerCase()]} className={classes.icons} />} title={action.name} subheader={action.condition} />
                            </Card>
                          )}
                        </Draggable>
                      ))}
                    </Grid>
                  </Box>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Box>
        </Paper>
      </Grid>
    </DragDropContext>
  );
};
