import React, { FunctionComponent, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { AssetContextProvider, availablePanelsByType, useAssetContext } from '../commons/context/AssetContext';
import { AssetViewController } from './components/AssetViewController';
import { ProjectAssets } from './components/ProjectAssets';
import { Editor } from '../../Private/Editor/Editor';
import { AssetView } from '../commons';
import { Loading } from '../../../components';
import { NoSelectedAssetView } from '../commons/components/NoSelectedAssetView';
import { StubbornData } from '../../../business';
import { AssetViewType } from '../commons/components/AssetView/types';
import { useEditionAssetFind } from '../../../../platform/api/endpoints/edition';
import { useStyles } from './styles';

const panels: Record<
  string,
  FunctionComponent<{
    asset: StubbornData;
    type: AssetViewType;
  }>
> = {
  assetView: AssetView,
  editor: Editor,
};

export const PanelController = () => {
  const { activePanel, selectedAsset } = useAssetContext();
  const Comp = panels[activePanel] || panels.assetView;
  const { data, loading, error, call } = useEditionAssetFind({ autoCall: false });

  useEffect(() => {
    if (selectedAsset) {
      call({ id: selectedAsset.type, query: { name: selectedAsset.name } });
    }
  }, [call, selectedAsset]);

  if (!selectedAsset) {
    return <NoSelectedAssetView />; // TODO: armar el "Not selected";
  }
  const availablePanels = availablePanelsByType[selectedAsset.type] || availablePanelsByType.Datasource;
  if (loading || !availablePanels.includes(activePanel)) {
    return <Loading />;
  }
  if (data && data.length) {
    return <Comp asset={data[0]} type={selectedAsset.type as AssetViewType} />;
  }
  return <h1>Error loading asset: {error}</h1>;
};

export const Main = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <AssetContextProvider>
      <Grid container style={{ display: 'flex', flex: 1, flexDirection: 'row', width: '100%', height: "100%" }}>
        <Grid item className={classes.assetContainer} style={{ height: '100%', overflowY: 'auto' }}>
          <ProjectAssets />
        </Grid>
        <Grid item style={{ display: 'flex', flex: 1, margin: 0, padding: 0, height: '100%' }}>
          <PanelController />
        </Grid>
      </Grid>
    </AssetContextProvider>
  );
};
