import React, { useCallback, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Icon } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Editor, { useMonaco } from '@monaco-editor/react';
import { BaseAttributeEditorProps } from './BaseAttributeEditorProps';
import { Modal } from '../../../../../../../commons/ui';
import { useScreenDAOContext } from '../../context/ScreenDAOContext';
import { useProjectDAOContext } from '../../context/ProjectDAOContext';
import { ServicesAppContext } from '../../../../appContext/ServicesAppContext';
import { useProfileDAO } from '../../hooks';
import { useRouterDAOContext } from '../../context/RouterDAOContext';

const useStyles = makeStyles({
  backdrop: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 16,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#00000080',
    zIndex: 2000000,
    top: 0,
    left: 0,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    height: '75%',
    width: '80%',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
  },
  modalContent: {
    display: 'flex',
    flex: 1,
    padding: '0px 10px',
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    justifyContent: 'flex-end',
  },
  cancelButton: { marginRight: 20 },
});

export interface CodeAttributeEditorProps extends BaseAttributeEditorProps {}

export const CodeAttributeEditor = ({ value, onChange, onCancel, onSave, cellName }: CodeAttributeEditorProps) => {
  const monaco = useMonaco();
  const classes = useStyles();
  const { ScreenDAO } = useScreenDAOContext();
  const { ProjectDAO } = useProjectDAOContext();
  const { ProfileDAO } = useRouterDAOContext();

  const handleOnBlur = useCallback(
    (event) => {
      onSave();
      event.stopPropagation();
    },
    [onSave],
  );

  useEffect(() => {
    if (monaco && ProjectDAO && ScreenDAO) {
      monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: true,
        noSyntaxValidation: false,
      });

      // compiler options
      monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        target: monaco.languages.typescript.ScriptTarget.ES2015,
        allowNonTsExtensions: true,
      });
      monaco.languages.typescript.javascriptDefaults.addExtraLib([ProjectDAO?.getProyectType(), ScreenDAO?.getScreenTypes(), ProfileDAO?.RouterDAO?.getRouterTypes()].join('\n'), 'file/file.d.ts');
    }
  }, [monaco, ProjectDAO, ScreenDAO, ProfileDAO]);

  return (
    <Modal>
      <div className={classes.backdrop} onClick={onCancel}>
        <div
          className={classes.modal}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={classes.modalHeader}>
            <h3>{cellName}</h3>
            <Icon component={CloseIcon} onClick={onCancel} />
          </div>
          <div className={classes.modalContent}>
            <Editor height="100%" width="100%" theme="vs-dark" defaultLanguage="javascript" defaultValue={value?.toString()} onChange={onChange} />
          </div>
          <div className={classes.modalFooter}>
            <Button className={classes.cancelButton} variant="outlined" fullWidth={false} onClick={onCancel}>
              Cancelar
            </Button>
            <Button variant="contained" fullWidth={false} onClick={handleOnBlur}>
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
