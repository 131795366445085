import React, {FunctionComponent, ReactNode} from 'react';
import DnsIcon from '@material-ui/icons/Dns';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import AltRouteIcon from '@material-ui/icons/AltRoute';
import SaveIcon from '@material-ui/icons/Save';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import ExtensionIcon from '@material-ui/icons/Extension';
import {NewScreenModal} from './modals/NewScreenModal';
import {NewServiceModal} from './modals/NewServiceModal';
import {NewRouterModal} from './modals/NewRouterModal';
import {NewDataSourceModal} from './modals/NewDataSourceModal';
import {NewSessionConfigurationModal} from './modals/NewSessionConfigurationModal';

import {NewAssetProps} from './types';
import {NewPluginConfigurationModal} from "./modals/NewPluginConfigurationModal";


interface TypeConfig {
    group: string;
    parent?: string;
    icon?: ReactNode;
    createModal?: FunctionComponent<NewAssetProps>;
  }

 export const typesConfig: { [k: string]: TypeConfig } = {
    Screen: {
      group: 'Screens',
      icon: <WebAssetIcon />,
      createModal: NewScreenModal,
    },
    Service: {
      group: 'Services',
      icon: <DnsIcon />,
      createModal: NewServiceModal,
    },
    Router: {
      group: 'Routers',
      icon: <AltRouteIcon />,
      createModal: NewRouterModal,
    },
    SessionConfiguration: {
      group: 'Session',
      parent: 'Configuration',
      icon: <SupervisedUserCircleIcon />,
      createModal: NewSessionConfigurationModal,
    },
   PluginConfiguration: {
     group: 'Plugins',
     parent: 'Configuration',
     icon: <ExtensionIcon />,
     createModal: NewPluginConfigurationModal,
   },
    Datasource: {
      group: 'Datasource',
      parent: 'Configuration',
      icon: <SaveIcon />,
      createModal: NewDataSourceModal,
    },
  };
