import React, { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '../../../../../../../../commons/hooks';
import SearchIcon from '@material-ui/icons/Search';
import { TextField, Typography, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AppThemeType } from '../../../../../../../../../theme';

export interface SearchInputProps {
  search: string;
  fontColor?: string;
  onSearchChange: (search: string) => void;
}

const useStyles = makeStyles<AppThemeType>(() => ({
  container: {
    border: 'none',
  },
}));

export const SearchInput = ({ search, onSearchChange, fontColor }: SearchInputProps) => {
  const [searchState, setSearchState] = useState(search);
  const classes = useStyles();
  const searchDebounced = useDebounce(searchState, 1000);

  const onSearchInputChange = useCallback((event) => {
    setSearchState(event.target.value);
  }, []);

  useEffect(() => {
    if (search !== searchDebounced) {
      onSearchChange(searchDebounced);
    }
  }, [onSearchChange, search, searchDebounced]);

  return (
    <TextField
      label=""
      value={searchState}
      onChange={onSearchInputChange}
      className={classes.container}
      variant="filled"
      InputProps={{
        style: {
          color: 'white',
        },
        startAdornment: (
          <InputAdornment position="start">
            <span style={{ color: 'white' }}>Buscar:</span>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" style={{ color: 'white' }}>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
