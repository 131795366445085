import React, { ReactNode, ReactNodeArray } from 'react';
import Typography from '@material-ui/core/Typography';

export interface ScreenTitleProps {
  children: ReactNode | ReactNodeArray;
}

export const ScreenTitle = ({ children }: ScreenTitleProps) => (
  <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white'}}>{children}</Typography>
);
