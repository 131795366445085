import React, { useCallback, useState } from 'react';
import { StubbornRouter } from '../../../../../../../business/StubbornRouter';
import { useEditionRouterCreate } from '../../../../../../../../platform/api/endpoints/edition';
import { NewRouterDialog } from '../../../../../../Private/Dashboard/components/AssetList/components/Toolbar/components/AddAssetMenu/dialogs';
import { NewAssetProps } from '../../types';

export const NewRouterModal = ({ onComplete, onClose }: NewAssetProps) => {
  const [modalOpen, setModalOpen] = useState(true);
  const { call: createRouter } = useEditionRouterCreate();
  const onCloseModal = useCallback(() => {
    setModalOpen(false);
    setTimeout(() => onClose(), 0);
  }, [onClose]);
  const onCreateRouter = useCallback(
    async (name: string) => {
      const newRouter: StubbornRouter = {
        name,
        type: 'Router',
        basePath: '',
        layout: undefined,
        routes: [],
        redirect: [],
        events: {},
        attributes: { basic: { layouts: {} } },
        tags: [],
      };
      await createRouter(newRouter);
      onComplete();
      onCloseModal();
    },
    [createRouter, onCloseModal, onComplete],
  );

  return <NewRouterDialog open={modalOpen} onClose={onCloseModal} onCreateRouter={onCreateRouter} />;
};
