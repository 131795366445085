import React, { useCallback, useState } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Icon } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Editor from "@monaco-editor/react";
import { BaseAttributeEditorProps } from "./BaseAttributeEditorProps";
import { DataGrid, GridColDef, GridRowData } from "@material-ui/data-grid";
import { useEditorServiceContext } from "../../../../EditorServiceContext";
import { TextField } from "@material-ui/core";
import {AnyObject} from "../../../../../../../commons/types";
import {Modal} from "../../../../../../../commons/ui";

const useStyles = makeStyles({
  backdrop: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingRight: 16,
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#00000080",
    zIndex: 2000000,
    top: 0,
    left: 0,
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    height: "75%",
    width: "80%",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 10px",
  },
  modalContent: {
    display: "flex",
    flex: 1,
    padding: "0px 10px",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  flexContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: 5,
  },
  responseContainer: {
    display: "flex",
    flex: 2,
    flexDirection: "column",
    padding: 5,
  },
  paramsHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    justifyContent: "flex-end",
  },
  cancelButton: { marginRight: 20 },
  paramsInput: {
    padding: 10,
  },
  paramsInputContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
});

export interface SqlAttributeEditorProps extends BaseAttributeEditorProps {}

export const SqlAttributeEditor = ({
  value,
  onChange,
  onCancel,
  onSave,
  cellName,
}: SqlAttributeEditorProps) => {
  const classes = useStyles();
  const {
    service: { parameters },
  } = useEditorServiceContext();
  const [params, setParams] = useState<AnyObject>({});
  const [dataGridInfo, setDataGridInfo] = useState<{
    rows: GridRowData[];
    columns: GridColDef[];
  }>({
    rows: [],
    columns: [],
  });

  const handleOnBlur = useCallback(
    (event) => {
      onSave();
      event.stopPropagation();
    },
    [onSave]
  );

  const handleRunQuery = useCallback(async () => {
    const res = await fetch("https://jsonplaceholder.typicode.com/todos");
    const data = await res.json();

    const columns = Object.keys(data[0]).map(
      (key, index): GridColDef => ({
        field: index.toString(),
        headerName: key,
        width: 140,
      })
    );
    const rows = data?.map((value: GridRowData, id: number) => ({
      id,
      ...Object.values(value),
    }));

    setDataGridInfo({ columns, rows });
  }, []);

  const handleChangeParams = useCallback(
    (event, name: string) => {
      setParams({
        ...params,
        [name]: event.target.value,
      });
    },
    [setParams, params]
  );

  return (
    <Modal>
      <div className={classes.backdrop} onClick={onCancel}>
        <div
          className={classes.modal}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={classes.modalHeader}>
            <h3>{cellName}</h3>
            <Icon component={CloseIcon} onClick={onCancel} />
          </div>
          <div className={classes.modalContent}>
            <div className={classes.columnContainer}>
              <div className={classes.flexContainer}>
                <Editor
                  height="100%"
                  width="100%"
                  theme="vs-dark"
                  defaultLanguage="sql"
                  defaultValue={value?.toString()}
                  onChange={onChange}
                />
              </div>
              <div className={classes.flexContainer}>
                <div className={classes.paramsHeader}>
                  <h3>Params:</h3>
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleRunQuery}
                    >
                      Run
                    </Button>
                  </div>
                </div>
                <div className={classes.paramsInputContainer}>
                  {parameters.map(({ name }) => (
                    <div className={classes.paramsInput}>
                      <TextField
                        name={name}
                        label={name}
                        value={`${params?.[name] || ""}`}
                        onChange={(e) => handleChangeParams(e, name)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={classes.columnContainer}>
              <div className={classes.responseContainer}>
                <h3>Response:</h3>
                <DataGrid
                  rows={dataGridInfo.rows}
                  columns={dataGridInfo.columns}
                />
              </div>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <Button
              className={classes.cancelButton}
              variant="outlined"
              fullWidth={false}
              onClick={onCancel}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              fullWidth={false}
              onClick={handleOnBlur}
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
