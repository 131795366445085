import React, { createContext, Dispatch, ReactNode, ReactNodeArray, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EditorItem } from './EditorItem';
import { StubbornAsset, StubbornField, StubbornScreen } from '../../../business/StubbornAsset';
import { getAssetByAssetSelector, setAssetByAssetSelector } from '../../../utils';
import { AssetNamingHelper } from '../../../utils/AssetNamingHelper';
import { ScreenDAOContextProvider } from './editors/ScreenEditor/context/ScreenDAOContext';
import { ProjectDAOContextProvider } from './editors/ScreenEditor/context/ProjectDAOContext';
import { RouterDAOContextProvider } from './editors/ScreenEditor/context/RouterDAOContext';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

export interface EditorScreenContextType {
  // esto es del contexto del editor
  assetSelector: number[];
  setAssetSelector: Dispatch<SetStateAction<number[]>>;
  parentSelector: number[];
  setParentSelector: Dispatch<SetStateAction<number[]>>;
  editable: boolean;
  setEditable: Dispatch<SetStateAction<boolean>>;
  toggleEditable: () => void;
  screen: StubbornScreen;
  setScreen: Dispatch<SetStateAction<StubbornScreen>>;
  addAsset: (asset: StubbornAsset, selector?: number[]) => void;
}

export const EditorScreenContext = createContext<EditorScreenContextType>({
  assetSelector: [],
  setAssetSelector: () => {},
  setParentSelector: () => {},
  editable: true,
  setEditable: () => {},
  toggleEditable: () => {},
  // @ts-ignore
  screen: {},
  setScreen: () => {},
  addAsset: (asset, selector) => {},
});

export interface EditorScreenContextProviderProps {
  children: ReactNode | ReactNodeArray;
  editor: EditorItem<StubbornScreen>;
  isEditable?: boolean;
}

export const EditorScreenContextProvider = ({ children, editor, isEditable = true }: EditorScreenContextProviderProps) => {
  const [screen, setScreen] = useState<StubbornScreen>(editor.getItem());
  const [assetSelector, setAssetSelector] = useState<number[]>([]);
  const [parentSelector, setParentSelector] = useState<number[]>([]);
  const [editable, setEditable] = useState<boolean>(isEditable);
  const [counter, setCounter] = useState(0);
  const toggleEditable = useCallback(() => setEditable((prev) => !prev), []);

  const addAsset = useCallback(
    (asset: StubbornAsset, selector: number[] = []) => {
      // si el selector esta vacio significa que es el screen base
      if (!selector.length) {
        return setScreen((prev) => {
          const nameManager = new AssetNamingHelper(prev);
          return {
            ...prev,
            metadata: [...prev.metadata, { ...asset, name: nameManager.getDefaultNameByType(asset as StubbornField) }],
          };
        });
      }
      // Si el seleccionado es un screen se adicionamos
      const selectedAsset = getAssetByAssetSelector(screen, selector);
      if (selectedAsset.type === 'Screen') {
        const selectedScreen = { ...(selectedAsset as StubbornScreen) };
        const nameManager = new AssetNamingHelper(selectedScreen);
        selectedScreen.metadata = [...selectedScreen.metadata, { ...asset, name: nameManager.getDefaultNameByType(asset as StubbornField) }];
        const newScreen = setAssetByAssetSelector(screen, selector, selectedScreen);
        return setScreen(newScreen);
      }
      // si es field buscamos si screen container
      const containerSelector = [...selector];
      containerSelector.pop();
      if (!containerSelector.length) {
        return setScreen((prev) => {
          const nameManager = new AssetNamingHelper(prev);
          return {
            ...prev,
            metadata: [...prev.metadata, { ...asset, name: nameManager.getDefaultNameByType(asset as StubbornField) }],
          };
        });
      }
      const selectedScreen: StubbornScreen = getAssetByAssetSelector(screen, containerSelector) as StubbornScreen;
      const nameManager = new AssetNamingHelper(selectedScreen);
      selectedScreen.metadata = [...selectedScreen.metadata, { ...asset, name: nameManager.getDefaultNameByType(asset as StubbornField) }];
      const newScreen = setAssetByAssetSelector(screen, containerSelector, selectedScreen);
      return setScreen(newScreen);
    },
    [screen],
  );

  const value = useMemo<EditorScreenContextType>(
    () => ({
      assetSelector,
      setAssetSelector,
      parentSelector,
      setParentSelector,
      editable,
      setEditable,
      toggleEditable,
      screen,
      setScreen,
      addAsset,
    }),
    [assetSelector, editable, toggleEditable, screen, addAsset, parentSelector],
  );
  useEffect(() => {
    editor.setItem(screen);
  }, [editor, screen]);
  // este use effect es para forzar la llamada para actualizar los assets del ProjectDAO
  useEffect(() => {
    if (!editable) {
      setCounter((prev) => prev + 1);
    }
  }, [editable]);
  return (
    <EditorScreenContext.Provider value={value}>
      <ProjectDAOContextProvider key={counter}>
        {
          //<RouterDAOContextProvider key={`router-${counter}`}>
          <ScreenDAOContextProvider>{children}</ScreenDAOContextProvider>
          //</RouterDAOContextProvider>
        }
      </ProjectDAOContextProvider>
    </EditorScreenContext.Provider>
  );
};

export const useEditorScreenContext = () => useContext<EditorScreenContextType>(EditorScreenContext);
