import {StubbornAttribute, StubbornAttributeGroup} from "../../../../../../../../business/StubbornAttributeMapping";

import { basicAttributeGroup, makeComponentAttributeGroup, makeEventAttributeGroup } from "../../commons/attributes";

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Contenido',
  name: 'content',
  type: 'string',
  default: 'Contenido',
});
attributes.add({
  label: 'Color',
  name: 'color',
  type: 'select',
  default: 'info',
  list: ['error', 'info', 'success', 'warning']
});
attributes.add({
  label: 'Gravedad',
  name: 'severity',
  type: 'select',
  default: 'info',
  list: ['error', 'info', 'success', 'warning']
});
attributes.add({
  label: 'Variante',
  name: 'variant',
  type: 'select',
  default: 'filled',
  list: ['filled', 'outlined', 'standard']
});
attributes.add({
  label: 'Texto de cerrar',
  name: 'closeText',
  type: 'string',
  default: 'Cerrar',
});
attributes.add({
  label: 'Rol',
  name: 'role',
  type: 'string',
  default: 'alert',
});
attributes.add({
  label: 'Success Icon',
  name: 'successIcon',
  type: 'string',
  default: '',
});
attributes.add({
  label: 'Info Icon',
  name: 'infoIcon',
  type: 'string',
  default: '',
});
attributes.add({
  label: 'Warning Icon',
  name: 'warningIcon',
  type: 'string',
  default: '',
});
attributes.add({
  label: 'Error Icon',
  name: 'errorIcon',
  type: 'string',
  default: '',
});

const eventAttributes: StubbornAttribute[] = [
  {
    label: 'OnClose',
    name: 'onClose',
    type: 'code',
    default: '',
  },
];

export const attributeGroups: StubbornAttributeGroup[] = [
  basicAttributeGroup,
  makeComponentAttributeGroup(attributes),
  makeEventAttributeGroup(eventAttributes),
];
