import {StubbornAttribute, StubbornAttributeGroup} from "../../../../../../../../business/StubbornAttributeMapping";

import {
  basicAttributeGroup,
  makeComponentAttributeGroup,
  makeCustomAttributeGroup,
  makeEventAttributeGroup,
  makeStateAttributeGroups
} from "../../commons/attributes";

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Elevación (sombra)',
  name: 'raised',
  type: 'boolean',
  default: false,
});

export const mediaAttributes = new Set<StubbornAttribute>();
mediaAttributes.add({
  label: 'Enabled',
  name: 'enabled',
  type: 'boolean',
  default: false,
});
mediaAttributes.add({
  label: 'Source',
  name: 'src',
  type: 'string',
  default: '',
});
mediaAttributes.add({
  label: 'Alt',
  name: 'alt',
  type: 'string',
  default: '',
});
mediaAttributes.add({
  label: 'Width',
  name: 'width',
  type: 'string',
  default: '',
});
mediaAttributes.add({
  label: 'Height',
  name: 'height',
  type: 'string',
  default: '',
});

export const contentAttributes = new Set<StubbornAttribute>();
contentAttributes.add({
  label: 'Enabled',
  name: 'enabled',
  type: 'boolean',
  default: false,
});
contentAttributes.add({
  label: 'Título',
  name: 'title',
  type: 'string',
  default: '',
});
contentAttributes.add({
  label: 'Texto',
  name: 'text',
  type: 'string',
  default: '',
});
contentAttributes.add({
  label: 'Style',
  name: 'style',
  type: 'breakpoints',
  default: {},
  metadata: { dataType: 'styles' },
});

export const actionAttributes = new Set<StubbornAttribute>();
actionAttributes.add({
  label: 'Enabled',
  name: 'enabled',
  type: 'boolean',
  default: false,
});
actionAttributes.add({
  label: 'Acciones',
  name: 'actions',
  type: 'collection',
  default: [],
  metadata: {
    dataType: "actions"
  },
});
actionAttributes.add({
  label: 'Style',
  name: 'style',
  type: 'breakpoints',
  default: {},
  metadata: { dataType: 'styles' },
});

export const headerAttributes = new Set<StubbornAttribute>();
headerAttributes.add({
  label: 'Enabled',
  name: 'enabled',
  type: 'boolean',
  default: false,
});
headerAttributes.add({
  label: 'Título',
  name: 'title',
  type: 'string',
  default: '',
});
headerAttributes.add({
  label: 'Subtítulo',
  name: 'subheader',
  type: 'string',
  default: '',
});
headerAttributes.add({
  label: 'Source (avatar)',
  name: 'avatarSrc',
  type: 'string',
  default: '',
});
headerAttributes.add({
  label: 'Descripción (avatar)',
  name: 'avatarLabel',
  type: 'string',
  default: '',
});
headerAttributes.add({
  label: 'Style',
  name: 'style',
  type: 'breakpoints',
  default: {},
  metadata: { dataType: 'styles' },
});

const eventAttributes: StubbornAttribute[] = [
  {
    label: "OnClick",
    name: "onClick",
    type: "code",
    default: "",
  },
];

const stateAttributes: StubbornAttribute[] = [
  {
    label: "Data",
    name: "data",
    type: 'object',
    default: '{}',
  },
];

export const attributeGroups: StubbornAttributeGroup[] = [
  basicAttributeGroup,
  makeStateAttributeGroups(stateAttributes),
  makeComponentAttributeGroup(attributes),
  makeCustomAttributeGroup('Header', 'header', headerAttributes),
  makeCustomAttributeGroup('Contenido', 'content', contentAttributes),
  makeCustomAttributeGroup('Media', 'media', mediaAttributes),
  makeCustomAttributeGroup('Acciones', 'actions', actionAttributes),
  makeEventAttributeGroup(eventAttributes),
];
