import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { MenuCollection } from '../../lib/phinxapp/profiles/types';
import { AppThemeType } from '../../theme';
import { BaseAppConst } from '../const/BaseAppConst';
import { AppMenu } from '../components';
import { useStubbornMenuContext } from '../context';
import { Grid, Icon } from '@material-ui/core';

const useStyles = makeStyles<AppThemeType>((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100vw',
      height: '100vh',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#707EF9',
    },
    logo: {
      fontWeight: 'bolder',
      fontSize: 28,
    },
    content: {
      flexGrow: 1,
      padding: 0,
    },
    mainContainer: {
      width: '100vw',
      background: '#DBDBDB',
      margin: 0,
      padding: 0,
      height: `calc(100vh - ${BaseAppConst.appBarHeight}px - 45px)`,
    },
  }),
);

interface MainLayoutProps {
  children: React.ReactNode;
  menu: MenuCollection;
}

export const MainLayout: FunctionComponent<MainLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const { toggleMenu } = useStubbornMenuContext();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h3" noWrap className={classes.logo}>
            Stubborn
          </Typography>
          <Typography style={{ marginLeft: 20, paddingTop: 5 }}>Project Name</Typography>
          <Icon style={{ fontSize: 16, marginTop: 5, marginLeft: 5 }}>keyboard_arrow_down</Icon>
        </Toolbar>
      </AppBar>
      <AppMenu />
      <main className={classes.content}>
        <Toolbar />
        <div className={classes.mainContainer}>{children}</div>
        <Grid container style={{ height: 45, display: 'flex', position: 'fixed', justifyContent: 'space-between', alignItems: 'center', width: '100%', backgroundColor: 'white', paddingLeft: 20, paddingRight: 20 }}>
          <Typography fontSize={13}>All rights reserved 2021. ©</Typography>
          <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography fontSize={13} style={{ marginRight: 5 }}>
              Powered by{' '}
            </Typography>
            <a href="https://www.phinxlab.com/" target="_blank" rel="noreferrer">
              <img alt="Phinx" src="https://www.phinxlab.com/images/logo/logo13.svg" width={42} />
            </a>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};
