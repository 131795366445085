import React, {useMemo} from 'react';
import {AnyObject} from "../../../../../../../../commons/types";
import {StubbornField} from "../../../../../../../../business/StubbornAsset";
import {Grid} from "@material-ui/core";
import {FieldRenderer, FieldRendererProps} from "../../../components/StubbornAssetRenderer/renderers";
import {GridProps} from "@material-ui/core/Grid/Grid";
import {GridSpans} from "../../../components";
import {cardFieldType} from "../Card";

export interface CardListItemProps {
  item: AnyObject;
  listAsset: StubbornField;
  index: number;
}

export const CardListItem = ({ listAsset, item, index }: CardListItemProps) => {
  const { attributes } = listAsset;
  const {
    cardBasic: { layouts },
  } = attributes;
  const gridProps = useMemo<GridProps>(
    () =>
      Object.entries(layouts).reduce<GridProps>(
        (acum, [breakpoint, layout]: [string, any]) => ({
          ...acum,
          [breakpoint]: layout.col,
        }),
        {},
      ),
    [layouts],
  );

  const fieldRendererProps = useMemo<FieldRendererProps>(() => ({
    name: `cardItem${index}`,
    fieldType: cardFieldType.name,
    type: "Field",
    attributes: {
      ...attributes,
      basic: attributes.cardBasic,
      states: { data: item },
      events: {
        onMount: attributes.events.onCardMount || '',
        onUnmount: attributes.events.onCardUnmount || '',
        onClick: attributes.events.onCardClick || '',
      },
    },
    Context: { Item: item },
    tags: [],
  }), [attributes, index, item]);

  return (
    <Grid item {...gridProps}>
      <GridSpans layouts={listAsset.attributes.cardBasic.layouts} />
      <FieldRenderer {...fieldRendererProps} />
      <GridSpans after layouts={listAsset.attributes.cardBasic.layouts} />
    </Grid>
  );
};
