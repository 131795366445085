import {MenuItem} from "../../../../lib/phinxapp/profiles/types";
import {Main} from "../../../../application/screens/Private2/Main";

export const routes: MenuItem[] = [
  {
    key: 'mainMain',
    basePath: '/',
    target: {
      component: Main,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Main',
    children: [],
    default: true,
  },
];
