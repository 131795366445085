import _ from 'lodash';
import React, { useCallback, useState, useEffect } from 'react';
import { Typography, Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField, Box, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { makeStyles, useTheme, createStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AppThemeType } from '../../../../../../../theme';
import { StubbornAsset, StubbornField } from '../../../../../../business/StubbornAsset';
import { getAssetByAssetSelector, setAssetByAssetSelector } from '../../../../../../utils';
import { FieldTypeManager } from '../fields';
import { EditorPropertiesDataGrid } from './EditorPropertiesDataGrid';
import { useEditorScreenContext } from '../../../EditorScreenContext';
import { BaseAppConst } from '../../../../../../const/BaseAppConst';
import { AnyObject } from '../../../../../../commons/types';
import { capitalizeFirstLetter } from '../fields/utils';
import { HIDDEN_SCREENS } from '../../../constants';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useDebounce } from '../../../../../../commons/hooks';
import { SearchInput } from '../../../../Dashboard/components/AssetList/components/Toolbar/components';
import { EditorToolbox } from '../toolbox/EditorToolbox';

type UIPropertiesProps = {};

const useStyles = makeStyles<AppThemeType>(() =>
  createStyles({
    drawer: {
      width: BaseAppConst.uiEditorProperties.drawerWidth + 35,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
      height: "100%",
    },
    attributesRow: {
      display: 'flex',
      width: '100%',
      flex: 1,
    },
    groupTitle: {
      margin: '0px',
      fontWeight: 'normal',
      color: 'white',
      backgroundColor: '#2F343D',
    },
    additionalControls: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '10px 0',
    },
    control: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 5px',
    },
    fieldTypeTitle: {
      margin: '10px 0 20px 10px',
      fontSize: 20,
    },
    tabContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    tab: {
      width: '100%',
      display: 'flex',
      height: 66,
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: 14,
      fontWeight: 600,
      color: 'white',
      backgroundColor: '#4b4e61',
    },
    selectedTab: {
      color: '#707ef9',
      width: '100%',
      display: 'flex',
      height: 66,
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: 14,
      fontWeight: 600,
    },
  }),
);

const defaultRows = [
  { id: 1, prop: 'name', value: 'myField' },
  { id: 2, prop: 'type', value: 'TextField' },
  { id: 3, prop: 'quantity', value: 0, type: 'number' },
  { id: 4, prop: 'isActive', value: false, type: 'boolean' },
];

// eslint-disable-next-line no-empty-pattern
export function EditorProperties({}: UIPropertiesProps) {
  const theme = useTheme();
  //@ts-ignore
  const classes = useStyles(theme);
  const { screen, setScreen, assetSelector, setAssetSelector, editable, parentSelector, setParentSelector } = useEditorScreenContext();
  const selectedAsset = getAssetByAssetSelector(screen, assetSelector);

  const [selectedIndex, setIndex] = useState<number | string>('');
  const [isOpenConfirmation, setIsOpenConfirmation] = useState<boolean>(false);
  const [metadataPath, setMetadataPath] = useState<any>(null);
  const [temporalName, setTemporalName] = useState<string>('');
  const [search, setSearch] = useState('');
  const [tabSelected, setTabSelected] = useState<string>('toolbar');

  useEffect(() => {
    setSearch('');
    const metadataPath = assetSelector
      .map((num, index) => {
        if (index < assetSelector.length - 1) {
          return `metadata[${num}]`;
        } else return `metadata`;
      })
      .join('.');
    const metadataToLook = metadataPath.length === 0 ? screen.metadata : _.get(screen, metadataPath);
    const index = metadataToLook.findIndex((item: any) => item.name === selectedAsset.name);

    setMetadataPath(metadataPath);
    assetSelector.length && setTabSelected('properties');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetSelector]);

  // esto se esta ejecutando cuando deseleccionas el elemento
  const onChange = useCallback(
    (newRows: AnyObject[]) => {
      // aplanamos los atributos
      const attr: AnyObject = newRows.reduce<AnyObject>(
        (acum, item: AnyObject) => ({
          ...acum,
          [item.prop]: item.value,
        }),
        {},
      );

      const metadataPath = assetSelector
        .map((num, index) => {
          if (index < assetSelector.length - 1) {
            return `metadata[${num}]`;
          } else return `metadata`;
        })
        .join('.');
      const metadataToLook = metadataPath.length === 0 ? screen.metadata : _.get(screen, metadataPath);
      const index = metadataToLook.findIndex((item: any) => item.name === selectedAsset.name);

      setMetadataPath(metadataPath);
      setIndex(index);
      setTemporalName(selectedAsset.name);

      const newSelectedAsset: StubbornAsset = {
        ...selectedAsset,
        attributes: {
          ...selectedAsset.attributes,
        },
      };

      Object.entries(attr).forEach(([item, value]) => {
        const [group, name] = item.split('.');
        newSelectedAsset.attributes[group] = {
          ...newSelectedAsset.attributes[group],
          [name]: value,
        };
      });
      const newScreen = setAssetByAssetSelector(screen, assetSelector, newSelectedAsset);
      setScreen(newScreen);
    },
    [selectedAsset, screen, assetSelector, setScreen],
  );

  const groupTitleRows: AnyObject[] = [];

  // @ts-ignore
  const fieldType = selectedAsset.type === 'Screen' && selectedAsset.fieldType !== 'dialog' ? FieldTypeManager.get('container') : FieldTypeManager.get((selectedAsset as StubbornField).fieldType);

  fieldType.attributeGroups.forEach((item, index) => {
    groupTitleRows.push(item);
  });

  const groupRows = groupTitleRows.map((item, index) => {
    const rows: AnyObject[] = [];
    item.attributes.forEach((attribute: any) => {
      if (attribute.label.toLowerCase().includes(search.toLowerCase())) {
        rows.push({
          id: `${index}#${attribute.name}`,
          prop: `${item.name}.${attribute.name}`,
          value: !selectedAsset.attributes[item.name] ? attribute.default : selectedAsset.attributes[item.name][attribute.name] || attribute.default,
          type: attribute.type,
          group: item.name,
          list: attribute.list,
          label: attribute.label,
          metadata: attribute.metadata,
        });
      }
    });
    return rows.length ? (
      <Accordion key={`attributes-group-${index}`} disableGutters={true} style={{ margin: 0, backgroundColor: '#2F343D', color: 'white', border: 0 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} aria-controls="panel1a-content" id="panel1a-header" style={{ padding: 15, color: 'white', height: 25 }}>
          <Typography className={classes.groupTitle}>{item.label}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 5 }} key={`attributes-row-${index}`}>
          {
            /* @ts-ignore && eslint-disable-next-line */ // le tuve que poner esto porque no me agarra el key
            <EditorPropertiesDataGrid initialRows={rows} onChange={onChange} key={`${item.name}-${rows.length}-Row-${index}`} />
          }
        </AccordionDetails>
      </Accordion>
    ) : null;
  });

  const onDelete = useCallback(() => {
    const screenCopy = { ...screen };
    const metadataToModify = _.get(screen, metadataPath);
    const index = metadataToModify.findIndex((item: any) => item.name === selectedAsset.name);
    metadataToModify.splice(index, 1);
    _.set(screenCopy, metadataPath, metadataToModify);

    setScreen(screenCopy);

    setIsOpenConfirmation(false);
    if (Object.values(HIDDEN_SCREENS).includes((selectedAsset as StubbornField).fieldType as HIDDEN_SCREENS)) {
      setParentSelector([]);
      setAssetSelector([]);
    } else {
      setAssetSelector(parentSelector.length ? parentSelector : []);
    }
  }, [selectedAsset, screen, setAssetSelector, setScreen, metadataPath, parentSelector, setParentSelector]);

  const deleteConfirmationScreen = (
    <Dialog open={isOpenConfirmation} onClose={() => setIsOpenConfirmation(false)}>
      <DialogTitle id="form-dialog-title">Delete</DialogTitle>
      <DialogContent>¿Are you sure you want to delete this component?</DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpenConfirmation(false)}>Cancel</Button>
        <Button onClick={onDelete}>Delete</Button>
      </DialogActions>
    </Dialog>
  );

  const onChangeName = (text: any) => {
    const newSelectedAsset: StubbornAsset = {
      ...selectedAsset,
      name: text,
    };

    const newScreen = setAssetByAssetSelector(screen, assetSelector, newSelectedAsset);
    setScreen(newScreen);
  };

  const onChangeOrder = (event: any) => {
    if (Number(event.target.value) || Number(event.target.value) === 0) setIndex(event.target.value);

    let orderToSet = event.target.value;

    if (orderToSet || orderToSet === 0) {
      if (orderToSet < 0 || orderToSet > screen.metadata.length - 1) orderToSet = selectedIndex;

      const screenCopy = { ...screen };

      const metadataToModify = _.get(screen, metadataPath);
      metadataToModify.splice(Number(selectedIndex), 1);
      metadataToModify.splice(orderToSet, 0, selectedAsset);
      _.set(screenCopy, metadataPath, metadataToModify);

      setScreen(screenCopy);
      setAssetSelector((prev) => {
        const newAssetSelector = [...prev];
        newAssetSelector[newAssetSelector.length - 1] = Number(orderToSet);
        return newAssetSelector;
      });
    }
  };

  const changeName = (e: any) => {
    onChangeName(e.target.value);
  };

  return (
    editable && (
      <Box className={classes.drawer} key={selectedAsset.name}>
        <div style={{ maxHeight: "90%" }}>
          <div className={classes.tabContainer}>
            <div className={tabSelected === 'toolbar' ? classes.selectedTab : classes.tab} onClick={() => setTabSelected('toolbar')}>
              Toolbox
            </div>
            <div className={tabSelected === 'properties' ? classes.selectedTab : classes.tab} onClick={() => setTabSelected('properties')}>
              Properties
            </div>
          </div>
          {tabSelected === 'properties' ? (
            <div style={{ maxHeight: "95%", overflowY: "auto" }}>
              <SearchInput search={search} onSearchChange={setSearch} key={search} />
              <Accordion key={`attributes-group-order`} style={{ margin: 0, cursor: 'unset', backgroundColor: '#2F343D', border: 0 }} expanded>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <Typography className={classes.groupTitle}>
                    {
                      // @ts-ignore
                      selectedAsset.type === 'Screen' && !Object.values(HIDDEN_SCREENS).includes(selectedAsset.fieldType as HIDDEN_SCREENS) ? 'Screen' : capitalizeFirstLetter(selectedAsset?.fieldType)
                    }
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow sx={{ '&:first-child td, &:first-child th': { height: 25, padding: 0, color: 'white', borderBottom: 0 } }}>
                        <TableCell component="th" scope="row" style={{ width: 120, paddingLeft: 10 }}>
                          Name
                        </TableCell>
                        <TableCell align="left">
                          <TextField
                            disabled={selectedIndex === -1}
                            variant="standard"
                            onChange={(e) => setTemporalName(e.target.value)}
                            onBlur={changeName}
                            value={temporalName}
                            style={{ paddingRight: 10 }}
                            InputProps={{
                              style: {
                                color: 'white',
                              },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { height: 25, padding: 0, color: 'white', borderBottom: 0 } }}>
                        <TableCell component="th" scope="row" style={{ width: 120, paddingLeft: 10 }}>
                          Order
                        </TableCell>
                        <TableCell align="left">
                          <TextField disabled={selectedIndex === -1} variant="standard" InputProps={{ inputProps: { min: 0, max: _.get(screen, metadataPath)?.length - 1, style: { color: 'white' } } }} type="number" value={selectedIndex} onChange={onChangeOrder} style={{ paddingRight: 10, width: '100%' }} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
              {groupRows}
            </div>
          ) : (
            <EditorToolbox />
          )}
        </div>
        <Button disabled={selectedIndex === -1} fullWidth color="primary" variant="contained" onClick={() => setIsOpenConfirmation(true)}>
          Delete
        </Button>
        {deleteConfirmationScreen}
      </Box>
    )
  );
}
