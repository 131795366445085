import {StubbornAttribute, StubbornAttributeGroup} from "../../../../../../../../business/StubbornAttributeMapping";

import { basicAttributeGroup, makeComponentAttributeGroup, makeEventAttributeGroup } from "../../commons/attributes";

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Content',
  name: 'content',
  type: 'string',
  default: 'Mi Texto',
});
attributes.add({
  label: 'Alineación',
  name: 'align',
  type: 'select',
  default: 'center',
  list: ["center", "inherit", "justify", "left", "right"]
});
attributes.add({
  label: 'Variant',
  name: 'variant',
  type: 'select',
  default: 'body1',
  list: ["h1", "h2", "h3", "h4", "h5", "h6", "subtitle1", "subtitle2", "body1", "body2", "caption", "button", "overline"]
});
attributes.add({
  label: 'Párrafo',
  name: 'paragraph',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'No wrap',
  name: 'noWrap',
  type: 'boolean',
  default: false,
});

export const attributeGroups: StubbornAttributeGroup[] = [
  basicAttributeGroup,
  makeComponentAttributeGroup(attributes),
  makeEventAttributeGroup(),
];
