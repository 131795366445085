import {useApiEndpoint} from "./useApiEndpoint";
import {useCallback, useEffect, useRef, useState} from "react";
import {QueryObject} from "../../RESTEndpoint";

export interface ApiFindOneOptions {
  id?: string;
  query?: QueryObject;
  autoCall?: boolean;
}

export interface ApiFindOneReturn<T> {
  data: T | null | undefined;
  error?: any;
  loading: boolean;
  call: (opts: Omit<ApiFindOneOptions, 'autoCall'>) => Promise<T | null | undefined>;
}

export const useApiFindOne = <T = any>(path: string, options: ApiFindOneOptions = {}): ApiFindOneReturn<T> => {
  const ServiceEndpoint = useApiEndpoint<T>(path);
  // Refs
  const idOption = useRef<string | undefined>(options.id);
  const queryOption = useRef<QueryObject | undefined>(options.query);
  const autoCallOption = useRef<boolean>(options.autoCall !== undefined ? options.autoCall : true);

  // Inner state
  const [data, setData] = useState<T | null | undefined>(undefined);
  const [error, setError] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const call = useCallback(async (opts: Omit<ApiFindOneOptions, 'autoCall'> = {}) => {
    const { id, query } = opts;
    try {
      setLoading(true);
      const idToUse = id !== undefined ? id : idOption.current;
      const queryToUse = query || queryOption.current;
      if (idToUse) {
        const response = await ServiceEndpoint.findOne(idToUse, queryToUse);
        setError(undefined);
        setData(response.data);
        return response.data;
      } else {
        setData(undefined);
        setError('id param required');
        throw { error: 'id param required' };
      }
    } catch (e: any) {
      setData(undefined);
      setError(e.error);
      throw e;
    } finally {
      setLoading(false);
    }
  }, [ServiceEndpoint]);

  useEffect(() => {
    if (autoCallOption.current) {
      call();
    }
  }, [call]);

  return { data, loading, error, call };
};
