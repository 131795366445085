import { API_URL } from '../../config';
import {Api, HttpServiceConstructor, FetchHttpService} from "../../lib/HttpService";

class AppApi extends Api {
  constructor() {
    super(API_URL, { headers: { 'Content-Type': 'application/json' }});
  }

  protected config(): void {
    this.registerEndpoint('/service');
    this.registerEndpoint('/roster');
    this.registerEndpoint('/roster/servicecontext');
    this.registerEndpoint('/roster/screencontext');
    this.registerEndpoint('/roster/routercontext');
    this.registerEndpoint('/roster/datasource');
    this.registerEndpoint('/edition/service');
    this.registerEndpoint('/edition/router');
    this.registerEndpoint('/edition/screen');
    this.registerEndpoint('/edition/datasource');
    this.registerEndpoint('/edition/configuration/session');
    this.registerEndpoint('/edition/asset');
    this.registerEndpoint('/edition/configuration/plugin');
  }

  protected getHttpService(): HttpServiceConstructor {
    return FetchHttpService;
  }

}

const instance = new AppApi();
export { instance as AppApi };
