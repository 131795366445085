import React, {useCallback} from 'react';
import {StubbornAttribute} from "../../../../../../../business/StubbornAttributeMapping";
import {StubbornService} from "../../../../../../../business/StubbornAsset";
import {Typography} from "@material-ui/core";
import {EditorPropertiesDataGrid} from "../../../ScreenEditor/properties/EditorPropertiesDataGrid";
import {useRowsFromAttributes} from "../../../../../../../commons/hooks/useRowsFromAttributes";
import {AnyObject} from "../../../../../../../commons/types";

const attributes = new Set<StubbornAttribute>();
attributes.add({
  name: 'name',
  type: 'string',
  label: 'Nombre',
});
attributes.add({
  name: 'description',
  type: 'string',
  label: 'Descripcion',
});
attributes.add({
  name: 'parameters',
  type: 'breakpoints',
  label: 'Parametros',
  default: {},
  metadata: {
    dataType: 'params',
  },
});

export interface ServicePropertyEditorProps {
  service: StubbornService;
  onServiceChange: (newService: StubbornService) => void;
}

export const ServicePropertyEditor = ({ service, onServiceChange }: ServicePropertyEditorProps) => {
  const rows = useRowsFromAttributes(attributes, service);
  const onChange = useCallback((newRows: AnyObject[]) => {
    const newService: StubbornService = { ...service };
    newRows.forEach((item) => {
      // @ts-ignore
      newService[item.prop as string] = item.value as any;
    });
    onServiceChange(newService);
  }, [onServiceChange, service]);
  return (
    <>
      <EditorPropertiesDataGrid initialRows={rows} onChange={onChange} />
    </>
  );
};
