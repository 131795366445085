import React, {useCallback, useState} from 'react';
import {StubbornScreen} from "../../../../../../../business/StubbornAsset";
import {useEditionScreenCreate} from "../../../../../../../../platform/api/endpoints/edition";
import {NewScreenDialog} from "../../../../../../Private/Dashboard/components/AssetList/components/Toolbar/components/AddAssetMenu/dialogs";
import {FieldTypeManager} from "../../../../../../Private/Editor/editors/ScreenEditor/fields";
import {NewAssetProps} from '../../types';


export const NewScreenModal = ({onComplete, onClose}: NewAssetProps) => {
    const [modalOpen, setModalOpen] = useState(true);
    const { call: createScreen } = useEditionScreenCreate();
    const onCloseModal = useCallback(() => {
      setModalOpen(false);
      setTimeout(() => onClose(), 0);
    }, [onClose]);
    const onCreateScreen = useCallback(async (name: string) => {
      const newScreen: StubbornScreen = {
        name,
        type: 'Screen',
        tags: [],
        metadata: [],
        attributes: { ...FieldTypeManager.getDefaultAttributes('container') },
      };
      await createScreen(newScreen);
      onComplete();
      onCloseModal();
    }, [createScreen, onCloseModal, onComplete]);
  
    return (
      <NewScreenDialog open={modalOpen} onClose={onCloseModal} onCreateScreen={onCreateScreen} />
    );
};