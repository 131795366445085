import React, { useCallback, useMemo, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LoadingButton from '@material-ui/lab/LoadingButton';
import Editor from '@monaco-editor/react';
import { useEditorServiceContext } from '../../../../EditorServiceContext';
import { useApiFindOne } from '../../../../../../../../lib/HttpService';
import { AnyObject } from '../../../../../../../commons/types';

export interface PreviewModalProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  modalContent: {
    display: 'flex',
    flex: 1,
    height: 600,
  },
  columnFlex: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
  },
  paramsInput: {
    padding: 10,
  },
  editorNotEditable: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
});

export const PreviewModal = ({ open, onClose }: PreviewModalProps) => {
  const classes = useStyles();
  const { service } = useEditorServiceContext();
  const { parameters } = service;
  const { data, loading, call, error } = useApiFindOne('/service', { autoCall: false, id: service.name });

  const [params, setParams] = useState<AnyObject>({});

  const handleChangeParams = useCallback(
    (event, name: string) => {
      setParams({
        ...params,
        [name]: event.target.value,
      });
    },
    [setParams, params],
  );

  const handleRunQuery = useCallback(() => {
    call({ query: params });
  }, [call, params]);

  const response = useMemo(() => JSON.stringify(data || error, null, '\t'), [data, error]);

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Preview</DialogTitle>
      <DialogContent>
        <div className={classes.modalContent}>
          <div className={classes.columnFlex} style={{ marginRight: 20 }}>
            <h4 style={{ margin: 0, padding: 10 }}>Parametros</h4>
            {parameters.map(({ name }) => (
              <div className={classes.paramsInput} key={name}>
                <TextField name={name} fullWidth label={name} value={`${params?.[name] || ''}`} onChange={(e) => handleChangeParams(e, name)} />
              </div>
            ))}
          </div>
          <div className={classes.columnFlex}>
            <Editor height="100%" width="100%" theme="vs-dark" defaultLanguage="json" value={response || ''} />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <LoadingButton variant="contained" onClick={handleRunQuery} loading={loading}>
          Run
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
