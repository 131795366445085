import { HttpService } from './HttpService';

export type QueryObject = {
  [k: string]: string | number;
};

export const getQueryString = (query: QueryObject) =>
  Object.entries(query)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

export class RESTEndpoint<T = any> {
  protected path: string;
  protected _httpService?: HttpService;

  constructor(path: string) {
    this.path = path;
  }

  get httpService() {
    if (!this._httpService) {
      throw new Error('Http Service not set');
    }
    return this._httpService;
  }

  set httpService(_httpService: HttpService) {
    this._httpService = _httpService;
  }

  getFullPathWithQuery(query: QueryObject = {}, suffix?: string) {
    const url = suffix ? `${this.path}/${suffix}` : this.path;
    if (!Object.keys(query).length) {
      return url;
    }
    return `${url}?${getQueryString(query)}`;
  }

  find(query?: QueryObject) {
    return this.httpService.get<T[]>(this.getFullPathWithQuery(query));
  }

  findOne(id: string, query?: QueryObject) {
    return this.httpService.get<T>(this.getFullPathWithQuery(query, id));
  }

  create(body: T, suffix?: string) {
    return this.httpService.post<T>(this.getFullPathWithQuery({}, suffix), body);
  }

  update(id?: string, body?: T) {
    const url = id ? `${this.path}/${id}` : this.path;
    return this.httpService.put<T>(url, body || {});
  }

  remove<S = T>(id?: string, body?: T) {
    const url = id ? `${this.path}/${id}` : this.path;
    return this.httpService.delete<S>(url, body || {});
  }
}
