import React, { useEffect, useCallback, useState, useMemo, ChangeEvent } from 'react';
import { Button, IconButton, InputLabel, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Icon } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { BaseAttributeEditorProps } from './BaseAttributeEditorProps';
import { Modal } from '../../../../../../../commons/ui';
import { useRosterServiceContextFind } from '../../../../../../../../platform/api/endpoints';
import { useInputOnChangeValue, useSelectOnChangeValue } from '../../../../../../../commons/hooks';
import { StubbornServiceParameter } from '../../../../../../../business/StubbornAsset';

const useStyles = makeStyles({
  backdrop: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 16,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#00000080',
    zIndex: 2000,
    top: 0,
    left: 0,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    height: '75%',
    overflowY: 'visible',
    overflowX: 'auto',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
  },
  modalContent: {
    display: 'flex',
    flex: 1,
    padding: '0px 10px',
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    justifyContent: 'flex-end',
  },
  cancelButton: { marginRight: 20 },
  switcher: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  params: {
    marginBottom: 20,
  },
  inputs: {
    marginRight: 10,
  },
  columns: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  selectContainer: { height: 55 },
});

interface Params {
  name: string;
  type: string;
  mandatory: boolean;
  default: string;
}

interface Service {
  serviceName: string;
  params: {
    keys: string[];
    values: string[];
  };
  path: string;
}

interface Esto {
  keys: string[];
  values: string[];
}

export interface ServiceDatasourceAttributeEditorProps extends BaseAttributeEditorProps {}

export const ServiceDatasourceAttributeEditor = ({ value, onChange, onCancel, onSave, cellName }: ServiceDatasourceAttributeEditorProps) => {
  const classes = useStyles();

  const [intermediateValue, setIntermediateValue] = useState<string>(JSON.stringify(value, null, '\t'));
  const [serviceParams, setServiceParams] = useState<StubbornServiceParameter[] | undefined>(undefined);
  const [readyToSave, setReadyToSave] = useState(false);
  const { data, loading } = useRosterServiceContextFind();
  const parsedValue = JSON.parse(intermediateValue);

  useEffect(() => {
    const parameters = data?.find((value) => value.name === parsedValue.serviceName)?.parameters;

    setServiceParams(parameters);
  }, [parsedValue.serviceName, data]);

  const handleOnBlur = useCallback(
    (event) => {
      onChange(parsedValue);
      setReadyToSave(true);
      event.stopPropagation();
    },
    [setReadyToSave, parsedValue, onChange],
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Escape':
          onCancel();
          e.stopPropagation();
          e.preventDefault();
          break;
      }
    },
    [onCancel],
  );

  const callOnBlur = (e: any) => {
    handleOnBlur(e);
  };

  useEffect(() => {
    if (readyToSave) onSave();
  }, [readyToSave, onSave]);

  const handleChangeParams = (key: string, index: number, paramsValue: string | boolean) => {
    const newParams = parsedValue;

    newParams.params[key][index] = paramsValue;

    setIntermediateValue(JSON.stringify(newParams, null, 2));
  };

  const handleChange = (key: string, value: string) => {
    const newParams = parsedValue;

    newParams[key] = value;

    setIntermediateValue(JSON.stringify(newParams, null, 2));
  };

  return (
    <Modal>
      <div className={classes.backdrop} onClick={onCancel}>
        <div
          className={classes.modal}
          style={{ width: 'auto' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={handleKeyPress}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={classes.modalHeader}
          >
            <h3>{cellName}</h3>
            <Icon component={CloseIcon} onClick={onCancel} />
          </div>
          <div className={classes.modalContent}>
            <div className={classes.columns}>
              <div style={{ display: 'flex', width: "100%", flexDirection: 'row' }}>
                <div style={{marginRight: 20}}>
                  <InputLabel>Service</InputLabel>
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <select
                      className={classes.selectContainer}
                      value={parsedValue.serviceName?.toString()}
                      onChange={(event) => {
                        handleChange('serviceName', event.target.value);
                      }}
                    >
                      <option key={`option-select-none`} value={''}>
                        Seleccione una opción
                      </option>
                      {data?.map((option, index) => (
                        <option key={`option-select-${option?.name}-${index}`} value={option?.name.toString()}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div>
                  <InputLabel>Path</InputLabel>
                  <TextField autoFocus={true} variant="outlined" value={parsedValue.path} onChange={(event) => handleChange('path', event.target.value)} onKeyDown={handleKeyPress} />
                </div>
              </div>
              {serviceParams?.map((param: Params, index: number) => (
                <div className={classes.params}>
                  <InputLabel style={{paddingTop: 10, paddingBottom: 10}}>Parameters</InputLabel>
                  <TextField onChange={(event) => handleChangeParams('keys', index, event.target.value)} variant="standard" className={classes.inputs} size="small" InputLabelProps={{ shrink: true }} label="Name" value={param.name} disabled />
                  <TextField onChange={(event) => handleChangeParams('values', index, event.target.value)} variant="standard" className={classes.inputs} size="small" InputLabelProps={{ shrink: true }} label="Value" value={param.default || parsedValue.params.values[index]} />
                </div>
              ))}
            </div>
          </div>
          <div className={classes.modalFooter}>
            <Button className={classes.cancelButton} variant="outlined" fullWidth={false} onClick={onCancel}>
              Cancelar
            </Button>
            <Button variant="contained" fullWidth={false} onClick={callOnBlur}>
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
