import { Button, Icon, IconButton, Input, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { Dispatch, FunctionComponent, SetStateAction, useCallback } from 'react';

export interface Mapper {
  from: string;
  to: string;
}

interface EditorMapperProps {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

const useStyles = makeStyles({
  inputs: {
    marginRight: 10,
  },
  columns: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  mapper: {
    marginBottom: 20,
  },
});

export const EditorMapper: FunctionComponent<EditorMapperProps> = ({ value, setValue }) => {
  const classes = useStyles();

  const addMapper = useCallback(() => {
    const newMapper = JSON.parse(value);
    newMapper.push({ from: '', to: '' });

    setValue(JSON.stringify(newMapper));
  }, [value, setValue]);

  const handleChangeMapper = (key: string, index: number, mapperValue: string) => {
    const newMapper = JSON.parse(value);

    newMapper[index][key] = mapperValue.trim();

    setValue(JSON.stringify(newMapper));
  };

  const handleDelete = useCallback((index: number) => {
    const newMapper = JSON.parse(value);

    newMapper.splice(index, 1)

    setValue(JSON.stringify(newMapper));
  }, [value, setValue]);

  return (
    <>
      <div className={classes.columns}>
        {JSON.parse(value).map((mapper: Mapper, index: number) => (
          <div className={classes.mapper}>
            <TextField onChange={(event) => handleChangeMapper('from', index, event.target.value)} variant="standard" className={classes.inputs} size="small" InputLabelProps={{ shrink: true }} label="From" value={mapper.from} />
            <TextField onChange={(event) => handleChangeMapper('to', index, event.target.value)} variant="standard" className={classes.inputs} size="small" InputLabelProps={{ shrink: true }} label="To" value={mapper.to} />
            <IconButton onClick={() => handleDelete(index)}>
              <Icon>clear</Icon>
            </IconButton>
          </div>
        ))}
        <Button variant="contained" onClick={addMapper}>
          Agregar mapper
        </Button>
      </div>
    </>
  );
};
