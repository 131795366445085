import {StubbornAttribute, StubbornAttributeGroup} from "../../../../../../../../business/StubbornAttributeMapping";

import { basicAttributeGroup, makeComponentAttributeGroup, makeEventAttributeGroup } from "../../commons/attributes";

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Texto',
  name: 'label',
  type: 'string',
  default: 'Load',
});
attributes.add({
  label: 'Loading',
  name: 'loading',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Disabled',
  name: 'disabled',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Loading position',
  name: 'loadingPosition',
  type: 'select',
  list: ["start", "end", "center"],
  default: 'start',
});
attributes.add({
  label: 'Loading size',
  name: 'loadingSize',
  type: 'string',
  default: '16',
});
attributes.add({
  label: 'Loading color',
  name: 'loadingColor',
  type: 'select',
  list: ["primary", "secondary", "inherit"],
  default: 'primary',
});
attributes.add({
  label: 'Loading indicator',
  name: 'loadingIndicator',
  type: 'string',
  default: '',
});
attributes.add({
  label: 'Variant',
  name: 'variant',
  type: 'select',
  default: 'contained',
  list: ["text", "outlined", "contained"]
});
attributes.add({
  label: 'Size',
  name: 'size',
  type: 'select',
  default: 'medium',
  list: ["small", "medium", "large"]
});
attributes.add({
  label: 'Ancho completo',
  name: 'fullWidth',
  type: 'boolean',
  default: true,
});
attributes.add({
  label: 'Start Icon',
  name: 'startIcon',
  type: 'string',
  default: "",
});
attributes.add({
  label: 'End Icon',
  name: 'endIcon',
  type: 'string',
  default: "",
});
attributes.add({
  label: 'Desactivar elevación (sombra)',
  name: 'disableElevation',
  type: 'boolean',
  default: false,
});

const eventAttributes: StubbornAttribute[] = [
  {
    label: "OnClick",
    name: "onClick",
    type: "code",
    default: "",
  },
  {
    label: "OnBlur",
    name: "onBlur",
    type: "code",
    default: "",
  },
];

export const attributeGroups: StubbornAttributeGroup[] = [
  basicAttributeGroup,
  makeComponentAttributeGroup(attributes),
  makeEventAttributeGroup(eventAttributes),
];
