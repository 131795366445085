export const breakpoints = [
  {
    name: "Extra-small (xs)",
    code: "xs",
    res: "600px",
  },
  {
    name: "Small (sm)",
    code: "sm",
    res: "960px",
  },
  {
    name: "Medium (md)",
    code: "md",
    res: "1280px",
  },
  {
    name: "Large (lg)",
    code: "lg",
    res: "1980px",
  },
  {
    name: "Extra-large (xl)",
    code: "xl",
    res: "2560px",
  },
];

export enum HIDDEN_SCREENS {
  DIALOG = "dialog"
};

export const toolboxCategories = ['Layouts', 'Inputs', 'Navigation', 'Surfaces', 'Feedback', 'Data Display', 'Data Grid', 'Utils']