import { GridSize } from '@material-ui/core/Grid/Grid';

export interface FieldLayout {
  col: GridSize | 0;
  span?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  spanAfter?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  [k: string]: any;
}

export type FieldLayoutBreakPoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type StubbornAssetAttributes = {
  basic: {
    layouts: { [k in FieldLayoutBreakPoint]?: FieldLayout };
    renderConditions?: string;
  };
  [k: string]: any;
};

export interface StubbornAsset {
  name: string;
  type: StubbornAssetType;
  tags: Set<string> | string[];
  // TODO: creo que el attributes debe estar en el stubborn asset
  attributes: StubbornAssetAttributes;
  fieldType?: string;
  metadata?: StubbornAsset[];
}

export interface StubbornField extends StubbornAsset {
  type: 'Field';
  fieldType: string; // el nombre del fieldType
}

export interface StubbornScreen extends StubbornAsset {
  type: 'Screen';
  metadata: StubbornAsset[];
  fieldType?: string;
}

export type StubbornServiceAction = {
  name: string;
  type: 'SQL' | 'Redis' | 'Expression' | 'Script';
  datasource: string;
  script: string;
  hasResponse: boolean;
  isList: boolean;
  mapper: Array<{ from: string; to: string }>;
  condition: string;
};

export type StubbornServiceParameter = {
  name: string;
  type: 'string' | 'number' | 'date' | 'array' | 'boolean' | 'object';
  mandatory: boolean;
  default: any;
};

// TODO: ver el tema de los attributes
export interface StubbornService extends StubbornAsset {
  type: 'Service';
  title: string;
  description: string;
  parameters: StubbornServiceParameter[];
  actions: StubbornServiceAction[];
  methods: any[];
}

export interface StubbornImage extends StubbornAsset {
  type: 'Image';
  title: string;
}

export type StubbornAssetType = 'Screen' | 'Service' | 'Menu' | 'Image' | 'Field' | 'Hidden' | 'Router';

export enum StubbornDatasourceType {
  POSTGRES = 'postgres',
  REDIS = 'redis',
  MYSQL = 'mysql',
}

export enum StubbornDatasourceKind {
  SQL = 'SQL',
  REDIS = 'Redis',
  CUSTOM = 'Custom',
}

export type StubbornDatasource = {
  name: string;
  kind: StubbornDatasourceKind;
  type: StubbornDatasourceType;
  host: string;
  port: number;
  user: string;
  password?: string;
  database?: string;
  onconnect?: string;
};

export enum StubbornAssetTypeKey {
  SCREEN = 'Screen',
  SERVICE = 'Service',
  ROUTER = 'Router',
  SESSIONCONFIGURATION = 'SessionConfiguration',
  PLUGINCONFIGURATION = 'PluginConfiguration',
  DATASOURCE = 'Datasource',
  POSTGRES = 'postgres',
}
