import { useMemo, useState } from 'react';
import { Box, Grid, Icon, IconButton, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { ScreenContainer, ScreenTitle } from '../../../../../components';
import { AssetViewManager } from './AssetViewManager';
import { AssetViewType, AssetView as AssetViewInterface } from './types';
import { ScreenAssetView, ServiceAssetView, SessionConfigurationAssetView, RouterAssetView, DatasourceConfigurationAssetView, PluginConfigurationAssetView } from './views';
import { StubbornData } from '../../../../../business';
import { availablePanelsByType, useAssetContext } from '../../context/AssetContext';

export interface AssetViewProps {
  asset: StubbornData;
  type: AssetViewType;
}

AssetViewManager.register(ScreenAssetView);
AssetViewManager.register(ServiceAssetView);
AssetViewManager.register(RouterAssetView);
AssetViewManager.register(SessionConfigurationAssetView);
AssetViewManager.register(DatasourceConfigurationAssetView);
AssetViewManager.register(PluginConfigurationAssetView);

export const AssetView = ({ asset, type }: AssetViewProps) => {
  const { panels } = useMemo(() => AssetViewManager.get(type) as AssetViewInterface, [type]);
  const [selectedIndex, setSelectedIndex] = useState('0');
  const { setActivePanel, selectedAsset } = useAssetContext();

  return (
    <ScreenContainer>
      <Grid container style={{ background: '#2F343D', height: 66, margin: 0, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
        <IconButton style={{ color: 'white' }} disabled={!selectedAsset || !availablePanelsByType[selectedAsset.type]?.includes('editor')} onClick={() => setActivePanel('editor')}>
          <Icon>edit</Icon>
        </IconButton>
        <ScreenTitle>{asset.name}</ScreenTitle>
      </Grid>
      <Grid container style={{ padding: 10, display: 'flex', flex: 1 }}>
        <Grid item md={12} style={{ backgroundColor: 'white', display: 'flex', flex: 1, height: '100%', flexDirection: 'column', border: '1px solid #CBCBCB' }}>
          {!!panels.length && (
            <TabContext value={selectedIndex}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={(event, newValue) => setSelectedIndex(newValue)}>
                  {panels.map((panel, index) => (
                    <Tab label={panel.label} value={index.toString()} key={index} />
                  ))}
                </TabList>
              </Box>
              {panels.map(({ component: Component }, index) => (
                <TabPanel value={index.toString()} key={index}>
                  <Component asset={asset} />
                </TabPanel>
              ))}
            </TabContext>
          )}
        </Grid>
      </Grid>
    </ScreenContainer>
  );
};
